/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
@tailwind base;

/**
  * This injects Tailwind's component classes and any component classes
  * registered by plugins.
  */
@tailwind components;

/**
  * This injects Tailwind's utility classes and any utility classes registered
  * by plugins.
  */
@tailwind utilities;
